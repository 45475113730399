
.rotate {
    animation: rotation 2s infinite linear;
}

.loader-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #060011;
    opacity: 0.95;
    z-index: 9999;
}

.loader-wrapper {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.text-wrapper {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}