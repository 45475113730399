@font-face {
    font-family: "FaricyNewRegular";
    src: url("/assets/font/TTF/FaricyNew-Regular.ttf") format("truetype");
  }
@font-face {
  font-family: "FaricyNewMedium";
  src: url("/assets/font/TTF/FaricyNew-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "FaricyNewLight";
  src: url("/assets/font/TTF/FaricyNew-Light.ttf") format("truetype");
}
@font-face {
  font-family: "FaricyNewBold";
  src: url("/assets/font/TTF/FaricyNew-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "FrutigerLTArabicBold";
  font-weight: bold;
  src: url("/assets/font/FrutigerLTArabic-65Bold.otf") format("opentype");
}
@font-face {
  font-family: "FrutigerLTArabicRoman";
  font-weight: normal;
  src: url("/assets/font/FrutigerLTArabic-55Roman.otf") format("opentype");
}
@font-face {
  font-family: "FrutigerLTArabicLight";
  font-weight: normal;
  src: url("/assets/font/FrutigerLTArabic-45Light.otf") format("opentype");
}

nav {
  padding: 20px 0 20px 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button:focus {
  outline: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}
html, body {
  background-color: #060011;
  color: #fff;
}
body {
  font-family: "FaricyNewRegular";
}
.MuiButtonBase-root:disabled {
  background-color: rgba(255, 255, 255, .2) !important;
  color: #c0c0c0 !important;
}
input[type="text"], input[type="tel"] {
  color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
  -webkit-text-fill-color: rgb(255, 255, 255);
  transition: background-color 5000s ease-in-out 0s;
}
.react-datepicker-wrapper input[type="text"], .react-datepicker-wrapper input[type="tel"] {
  color: #333;
  font-size: 14px;
}

.react-datepicker-wrapper .react-datepicker__close-icon:after {
  font-size: 20px;
  background: none;
  position: relative;
  top: -2px;
}